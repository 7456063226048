import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';

import useEventListener from '../../utils/use-event-listener';
import LangSelect from './components/lang-switcher';
import LangSelectMobile from './components/lang-switcher-mobile';
import Logo from '../../images/logo';
import Colors from '../../styles/colors';

const Header = () => {
    const muiTheme = useTheme();
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('md'), { noSsr: true });

    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        setDrawerOpen((prev) => !prev);
    };
    useEffect(() => {
        if (!isMobile) setDrawerOpen(false);
    }, [isMobile]);

    const [langSelectPos, setLangSelectPos] = useState(null);
    const drawerEl = document.getElementById('drawerWrapper');
    const resizeHandler = () => {
        const elemPos = document.getElementById('homeBtn').getBoundingClientRect().right;
        setLangSelectPos(elemPos);
    };
    useEffect(() => {
        resizeHandler();
    }, []);
    useEventListener('resize', resizeHandler);

    return (
        <>
            <header style={{ backgroundColor: Colors.darkBlue }}>
                <Container>
                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', height: 80 }}>
                        {/* Desktop Nav */}
                        <Grid item xs={4.1} display={{ xs: 'none', md: 'block' }}>
                            <Button variant="text" href="https://legalaidatwork.org/" id="homeBtn"><FontAwesomeIcon icon={faHouse} /> Back to Home</Button>
                            {/* <LangSelector /> */}
                            {!isMobile && <LangSelect orientingElemPos={langSelectPos} /> }
                        </Grid>
                        {/* Mobile Nav */}
                        <Grid item xs="auto" display={{ xs: 'block', md: 'none' }} style={{ minWidth: 50 }}>
                            <IconButton onClick={toggleDrawer} aria-label={drawerOpen ? 'close menu' : 'open menu'}>
                                <FontAwesomeIcon style={{ color: 'white' }} icon={drawerOpen ? faXmark : faBars} size={drawerOpen ? 'xl' : 'lg'} className={drawerOpen ? 'fa-2x' : ''} />
                            </IconButton>
                        </Grid>
                        <Grid item xs display="flex" justifyContent={{ xs: 'flex-start', md: 'center' }}>
                            <div style={{ height: isMobile ? 30 : 60, width: isMobile && 40 }}>
                                <Logo propColor="#FFFFFF" />
                            </div>
                        </Grid>
                        <Grid item xs sx={{ textAlign: 'right', minWidth: 175 }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                disableFocusRipple
                                disableRipple
                                onClick={() => window.location.replace('https://weather.com/')}
                                style={{ height: isMobile ? 40 : 44, width: isMobile && 140 }}
                            >
                                <FontAwesomeIcon icon={faXmark} /> Quick Escape
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </header>
            <div
                id="drawerWrapper"
                style={{
                    overflow: 'hidden',
                }}
            >
                <Drawer
                    anchor="top"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            backgroundColor: Colors.darkBlue,
                            boxShadow: 'none',
                            minHeight: '100vh',
                        },
                    }}
                    SlideProps={{
                        container: drawerEl,
                        appear: false,
                    }}
                >
                    <div>
                        <Container>
                            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', height: 80 }}>
                                {/* Desktop Nav */}
                                <Grid item xs={4.1} display={{ xs: 'none', md: 'block' }}>
                                    <Button variant="text" href="https://legalaidatwork.org/" id="homeBtn"><FontAwesomeIcon icon={faHouse} /> Back to Home</Button>
                                    {/* <LangSelector /> */}
                                    {!isMobile && <LangSelect orientingElemPos={langSelectPos} /> }
                                </Grid>
                                {/* Mobile Nav */}
                                <Grid item xs="auto" display={{ xs: 'block', md: 'none' }} style={{ minWidth: 50 }}>
                                    <IconButton onClick={toggleDrawer} aria-label={drawerOpen ? 'close menu' : 'open menu'}>
                                        <FontAwesomeIcon style={{ color: 'white' }} icon={drawerOpen ? faXmark : faBars} size={drawerOpen ? 'xl' : 'lg'} className={drawerOpen ? 'fa-2x' : ''} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs display="flex" justifyContent={{ xs: 'flex-start', md: 'center' }}>
                                    <div style={{ height: isMobile ? 30 : 60, width: isMobile && 40 }}>
                                        <Logo propColor="#FFFFFF" />
                                    </div>
                                </Grid>
                                <Grid item xs sx={{ textAlign: 'right', minWidth: 175 }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disableFocusRipple
                                        disableRipple
                                        onClick={() => window.location.replace('https://weather.com/')}
                                        style={{ height: isMobile ? 40 : 44, width: isMobile && 140 }}
                                    >
                                        <FontAwesomeIcon icon={faXmark} /> Quick Escape
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                        <Grid item container direction="column" style={{ color: Colors.darkBlue }} pt={2} pb={6} pl={1}>
                            <Grid item>
                                <Button variant="text" href="https://legalaidatwork.org/"><FontAwesomeIcon icon={faHouse} /> Back to Home</Button>
                            </Grid>
                            <Grid item>
                                <LangSelectMobile />
                            </Grid>
                        </Grid>
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default Header;
