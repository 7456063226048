import React, { Suspense, lazy } from 'react';
import {
    Routes,
    Navigate,
    Route,
} from 'react-router-dom';
import Container from '@mui/material/Container';
import withRoot from '../../wiring/with-root';
import themer from '../../styles/material-theme';
import Header from '../../components/header';
import ResultsHeader from '../../components/results-header';

const Home = lazy(() => import('../../routes/home'));
const IntakeQuestions = lazy(() => import('../../routes/intake'));
const Results = lazy(() => import('../../routes/results'));
const Error404 = lazy(() => import('../../routes/error/404'));

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <Header />
        <Routes>
            <Route path="/results" element={<ResultsHeader />} />
        </Routes>
        <main>
            <Container>
                <Routes>
                    <Route path="/error/404" element={<Error404 />} />
                    <Route path="/intake" element={<IntakeQuestions />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/*" element={<Navigate replace to="/error/404" />} />
                </Routes>
            </Container>
        </main>
    </Suspense>
);

export default withRoot(PublicLayout, themer);
