import React, { useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';

import useEventListener from '../../../../utils/use-event-listener';
import Colors from '../../../../styles/colors';

const StyledButton = styled('button')(() => ({
    backgroundColor: Colors.darkBlue,
    borderRadius: 12,
    height: 50,
    fontSize: '1.7rem',
    color: Colors.yellow,
    marginLeft: 15,
    paddingLeft: 10,
    '& .MuiSvgIcon-root': {
        marginRight: 5,
        fill: Colors.yellow,
        '&.MuiSelect-iconOpen': {
            fill: Colors.yellow,
        },
    },
    '&:focus': {
        border: '2px solid #00ACFF',
        boxShadow: '0 0 4px 1px rgba(0,172,255,0.5)',
    },
}));

const StyledMenu = styled('ul')(() => ({
    listStyle: 'none',
    backgroundColor: Colors.darkBlue,
    borderRadius: 12,
    display: 'none',
    '&.weglot-menu-open': {
        display: 'block',
        position: 'absolute',
        left: 175,
        top: 50,
        width: 200,
    },
}));
const StyledMenuItem = styled('button')(() => ({
    backgroundColor: Colors.darkBlue,
    height: 50,
    fontSize: '1.7rem',
    color: 'white',
    padding: '0 10px',
    marginLeft: -10,
    borderRadius: 5,
    '&:hover': {
        color: Colors.yellow,
    },
    '&:focus': {
        color: Colors.yellow,
        border: '1px solid #00ACFF',
        boxShadow: '0 0 4px 1px rgba(0,172,255,0.5)',
    },
}));
const StyledHR = styled('hr')(() => ({
    borderTop: `1px solid ${Colors.blue}`,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    marginLeft: -20,
    width: '100%',
}));

const LangSelector = ({ orientingElemPos }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const language = window.Weglot.getCurrentLang() || 'en';
    const handleUpdateLanguage = (value) => {
        window.Weglot.switchTo(value);
        setMenuOpen(false);
        document.getElementById('langBtn').focus();
    };

    const clickHandler = (evt) => {
        const flyoutEl = document.getElementById('desktop-lang-wrapper');
        let targetEl = evt.target; // clicked element
        do {
            if (targetEl === flyoutEl) return;
            // Go up the DOM
            targetEl = targetEl.parentNode;
        } while (targetEl);
        // This is a click outside.
        setMenuOpen(false);
    };

    const escHandler = (evt) => {
        if (evt.key === 'Escape' && menuOpen) {
            document.getElementById('langBtn').focus();
            setMenuOpen(false);
        }
    };

    useEventListener('click', clickHandler);
    useEventListener('keydown', escHandler);

    return (
        <div id="desktop-lang-wrapper" style={{ display: 'inline' }}>
            <StyledButton
                type="button"
                id="langBtn"
                onClick={() => setMenuOpen((prevVal) => !prevVal)}
                data-wg-notranslate
            >   <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 5 }} />
                <span style={{ paddingLeft: 5 }}>
                    {language === 'en' && 'English'}
                    {language === 'es' && 'Español'}
                    {language === 'zh' && '中文'}
                </span>
                <KeyboardArrowDownIcon style={{ marginLeft: 10 }} />
            </StyledButton>
            <StyledMenu className={menuOpen ? 'weglot-menu-open' : ''} style={{ left: orientingElemPos }}>
                {language !== 'en' && (
                    <li>
                        <StyledHR />
                        <StyledMenuItem type="button" onClick={() => handleUpdateLanguage('en')}>English</StyledMenuItem>
                    </li>
                )}
                {language !== 'es' && (
                    <li>
                        <StyledHR />
                        <StyledMenuItem type="button" onClick={() => handleUpdateLanguage('es')}>Español</StyledMenuItem>
                    </li>
                )}
                {language !== 'zh' && (
                    <li>
                        <StyledHR />
                        <StyledMenuItem type="button" onClick={() => handleUpdateLanguage('zh')}>中文</StyledMenuItem>
                    </li>
                )}
            </StyledMenu>
        </div>
    );
};
LangSelector.defaultProps = {
    orientingElemPos: null,
};
LangSelector.propTypes = {
    orientingElemPos: PropTypes.number,
};

export default LangSelector;
