import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import GA4React from 'ga-4-react';
import TagManager from 'react-gtm-module';

// let ga4React = null;
const tagManagerArgs = { gtmId: 'GTM-T9T2QBH' };

/*
const useGA4 = () => {
    if (ga4React) {
        return ga4React;
    }

    if (process.env.REACT_APP_GA4) {
        ga4React = new GA4React(process.env.REACT_APP_GA4);
        ga4React.initialize().then(() => { ga4React.isInitialized = true; });
        return ga4React;
    }

    return null;
};
*/
export const useGTM = () => {
    TagManager.initialize(tagManagerArgs);
};

const GoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'pageview',
            page: {
                url: location,
                title: location.pathname,
            },
        });
    }, [location]);

    // const ga = useGA4();
    /*
    useEffect(() => {
        if (ga && ga.isInitialized) {
            ga.pageview(location.pathname, location.search, location.pathname);
        }
    }, [location]);
    */
    return null;
};

export default GoogleAnalytics;
