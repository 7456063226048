import IntakeTypes from '../action-types/intake';

const internals = {
    initial: () => ({
        questionContent: {},
        answers: {},
        results: [],
    }),
};

const IntakeReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case IntakeTypes.SET_ANSWERS:

        return {
            ...state,
            answers: {
                ...payload,
            },
        };
    case IntakeTypes.CLEAR_ANSWERS:

        return {
            ...state,
            answers: {},
        };
    case IntakeTypes.LOAD_CONTENT_SUCCESS:
        return {
            ...state,
            questionContent: {
                ...payload,
            },
        };
    case IntakeTypes.GET_RESULTS_SUCCESS:
        return {
            ...state,
            results: [
                ...payload,
            ],
        };
    default:
        // do nothing
    }
    return state;
};

export default IntakeReducer;
