import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';

import Colors from '../../../../styles/colors';

const StyledSelectedLang = styled('div')(() => ({
    height: 40,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.5rem',
    color: 'white',
    marginLeft: 15,
    fontWeight: 700,
    '& .MuiSvgIcon-root': {
        marginRight: 5,
        fill: 'white',
    },
}));

const StyledMenu = styled('ul')(() => ({
    listStyle: 'none',
    marginTop: 0,
}));
const StyledMenuItem = styled('button')(() => ({
    height: 40,
    fontSize: '1.5rem',
    color: 'white',
    '&:hover': {
        color: Colors.yellow,
    },
}));

const LangSelectorMobile = () => {
    const language = window.Weglot.getCurrentLang() || 'en';
    const [triggerRerender, setTriggerRerender] = useState(true);
    const handleUpdateLanguage = (value) => {
        window.Weglot.switchTo(value);
        setTriggerRerender((prev) => !prev);
    };
    // triggerRerender forces a rerender, that for some reason the weglot var wasn't accomplishing on it's own
    return (
        <>
            <StyledSelectedLang data-wg-notranslate data-test={triggerRerender}>
                <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 5 }} />
                <span style={{ paddingLeft: 5 }}>
                    {language === 'en' && 'English'}
                    {language === 'es' && 'Español'}
                    {language === 'zh' && '中文'}
                </span>
            </StyledSelectedLang>
            <StyledMenu>
                {language !== 'en' && <li><StyledMenuItem type="button" onClick={() => handleUpdateLanguage('en')}>English</StyledMenuItem></li>}
                {language !== 'es' && <li><StyledMenuItem type="button" onClick={() => handleUpdateLanguage('es')}>Español</StyledMenuItem></li>}
                {language !== 'zh' && <li><StyledMenuItem type="button" onClick={() => handleUpdateLanguage('zh')}>中文</StyledMenuItem></li>}
            </StyledMenu>
        </>
    );
};

export default LangSelectorMobile;
