import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { getAnswers } from '../../selectors/intake';

import Colors from '../../styles/colors';

const StyledAnswerText = styled(Typography)({
    fontSize: '1.4rem',
    fontWeight: 400,
    color: 'white',
    display: 'inline',
});
const StyledAnswerDivider = styled(Typography)({
    fontSize: '1.3rem',
    fontWeight: 400,
    color: '#91B1C5',
    display: 'inline',
    paddingRight: 10,
    paddingLeft: 10,
});
const StyledLink = styled(Link)({
    fontSize: '1.4rem',
    fontWeight: 600,
    color: Colors.yellow,
});

const ResultsHeader = () => {
    const answers = useSelector(getAnswers);

    return (
        <div style={{ backgroundColor: Colors.darkBlue, height: 160 }}>
            <Container
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Grid container>
                    <Grid item sx={{ width: '100%', textAlign: 'center' }}>
                        <Typography variant="h1" sx={{ color: 'white', pb: 2 }}>Your Results</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sx={{ width: '100%', textAlign: 'center' }}>
                        <StyledAnswerText variant="inherit">{answers[1]}</StyledAnswerText>
                        <StyledAnswerDivider variant="inherit">|</StyledAnswerDivider>
                        <StyledAnswerText variant="inherit">{answers[2]}</StyledAnswerText>
                        <StyledAnswerDivider variant="inherit">|</StyledAnswerDivider>
                        <StyledAnswerText variant="inherit">{answers[3]}</StyledAnswerText>
                        <StyledAnswerDivider variant="inherit">|</StyledAnswerDivider>
                        <StyledAnswerText variant="inherit">{answers[4]}</StyledAnswerText>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sx={{ width: '100%', textAlign: 'center' }}>
                        <StyledLink to="/intake">Change Answers</StyledLink>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default ResultsHeader;
