import axios from 'axios';

import IntakeTypes from '../action-types/intake';

export const LoadContentBegin = () => ({
    type: IntakeTypes.LOAD_CONTENT_BEGIN,
});
const LoadContentSuccess = (payload) => ({
    type: IntakeTypes.LOAD_CONTENT_SUCCESS,
    payload,
});
const LoadContentError = (errorMsg) => ({
    type: IntakeTypes.LOAD_CONTENT_ERROR,
    payload: { errorMsg },
});

export const LoadContent = () => async (dispatch) => {
    dispatch(LoadContentBegin());
    axios.get(process.env.REACT_APP_API_HOST, {
        auth: {
            username: 'ck_73c83956b4e2b1bc3ce78ee7f491a3abb3fa2fe5',
            password: 'cs_c36d22b3fd54b2693afa8ef5ea30ea157d1bfe7c',
        },
    }).then(
        (payload) => dispatch(LoadContentSuccess(payload.data)),
        () => dispatch(LoadContentError()),
    );
};

export const GetResultsBegin = () => ({
    type: IntakeTypes.GET_RESULTS_BEGIN,
});
const GetResultsSuccess = (payload) => ({
    type: IntakeTypes.GET_RESULTS_SUCCESS,
    payload,
});
const GetResultsError = (errorMsg) => ({
    type: IntakeTypes.GET_RESULTS_ERROR,
    payload: { errorMsg },
});
const SetAnswers = (payload) => ({
    type: IntakeTypes.SET_ANSWERS,
    payload,
});

export const ClearAnswers = () => ({
    type: IntakeTypes.CLEAR_ANSWERS,
});

export const GetResults = (answers) => async (dispatch) => {
    dispatch(LoadContentBegin());
    dispatch(SetAnswers(answers));
    const answerParams = {};
    Object.keys(answers).forEach((a) => {
        answerParams[a] = encodeURIComponent(`Triage ${answers[a]}`);
    });
    axios.get(`${process.env.REACT_APP_CONTENT_URL}/wp-json/laaw/v1/triage-results?one=${answerParams[1]}&two=${answerParams[2]}&three=${answerParams[3]}&four=${answerParams[4]}`)
        .then(
            (payload) => dispatch(GetResultsSuccess(payload.data)),
            () => dispatch(GetResultsError()),
        );
};
