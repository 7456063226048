import { createSelector } from 'reselect';

const getIntakeState = (state) => state.intake;

export const getAnswers = createSelector(
    getIntakeState,
    (intakeState) => intakeState.answers,
);

export const getIntakeContent = createSelector(
    getIntakeState,
    (intakeState) => intakeState.questionContent,
);

export const getResults = createSelector(
    getIntakeState,
    (intakeState) => intakeState.results,
);
