import { createTheme } from '@mui/material/styles';
import Colors from './colors';

const baseTheme = createTheme({
    spacing: 5,
    typography: {
        color: '#242424',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        h1: {
            fontSize: '3.2rem',
            fontWeight: 700,
            color: Colors.darkBlue,
        },
        h2: {
            fontSize: '2.2rem',
            fontWeight: 700,
            color: Colors.darkBlue,
        },
        h3: {
            color: Colors.darkBlue,
            fontSize: '1.8rem',
            fontWeight: 700,
        },
        h4: {
            fontSize: '1.4rem',
            fontWeight: 800,
            letterSpacing: '1.5px',
            opacity: 0.8,
            textTransform: 'uppercase',
        },
        body1: {
            color: Colors.navyBlue,
            fontSize: '1.8rem',
            lineHeight: '2.3rem',
            fontWeight: 500,
        },
        body2: {
            opacity: 0.9,
            fontSize: '1.6rem',
            fontWeight: 400,
            color: Colors.navyBlue,
        },
        button: {
            textTransform: 'none',
            fontSize: '1.7rem',
            fontWeight: 700,
        },
    },
    palette: {
        text: {
            primary: Colors.darkBlue,
            secondary: Colors.navyBlue,
        },
        primary: {
            main: Colors.blue,
            dark: Colors.darkBlue,
            light: Colors.blue,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.yellow,
            light: Colors.yellow,
            contrastText: Colors.darkBlue,
        },
        background: {
            default: '#F0F1F3',
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
});
const theme = createTheme(baseTheme, {
    typography: {
        h1: {
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: '2.8rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '2.4rem',
            },
        },
        h2: {
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: '2.0rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.8rem',
            },
        },
        h3: {
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: '1.8rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.7rem',
            },
        },
        h4: {
            [baseTheme.breakpoints.down('lg')]: {
                fontSize: '1.4rem',
            },
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.3rem',
            },
        },
        body1: {
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
        body2: {
            [baseTheme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
            },
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    // color: `rgba(${Colors.textGreyRGB}, 0.7)`,
                    fontSize: '1.7rem',
                    fontWeight: 500,
                    color: Colors.darkBlue,
                },
                root: {
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 5,
                    '&.label-icon': {
                        // custom presentation, removes radio circle and uses user defined icon
                        '& svg.MuiSvgIcon-root, .MuiTouchRipple-root ': { height: 0, width: 0 },
                        '& .MuiFormControlLabel-label': {
                            textAlign: 'center',
                            width: '100%',
                        },
                    },
                    '&.Radio-label': {
                        paddingRight: 15,
                        backgroundColor: 'white',
                        borderRadius: 10,
                        width: '100%',
                        color: Colors.navyBlue,
                        '& svg': {
                            color: Colors.navyBlue,
                        },

                        '&:hover': {
                            borderRadius: 10,
                            backgroundColor: Colors.lightYellow,
                        },
                        '&:focus-within': {
                            paddingTop: 18,
                            paddingBottom: 18,
                            paddingLeft: 3,
                            paddingRight: 13,
                            outline: 0,
                            border: '2px solid #00ACFF',
                            boxShadow: '0 0 4px 1px rgba(0,172,255,0.5)',
                        },
                    },

                    '&.Radio-label-checked': {
                        color: Colors.darkBlue,
                        backgroundColor: Colors.yellow,
                        boxShadow: '0 4px 6px 0 rgba(51,71,91,0.1)',
                        '& svg': {
                            color: Colors.darkBlue,
                        },
                        '& $label': {
                            // color: Colors.textGrey,
                        },
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: Colors.darkBlue,
                    height: 20,
                    width: 20,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    ...baseTheme.typography.h1,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    top: -15,
                    left: -15,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    lineHeight: '1.4rem',
                    letterSpacing: '0.1rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.primary.dark,
                    '&::before': {
                        borderBottom: '0px !important', // used by lang select in header
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    fontSize: '1.6rem',
                    marginTop: 5,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation0: { // result blocks
                    textAlign: 'left',
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0 0 2px 0 rgba(47,76,103,0.2)',
                    padding: 15,

                },
                elevation16: { // drawer
                    // marginTop: 80,
                    // zIndex: -100,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '.MuiBackdrop-root': {
                        top: 80,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.text.secondary,
                    lineHeight: 1.1875,
                    fontSize: '1.6rem',

                    '&:hover': {
                        color: baseTheme.palette.primary.main,
                    },

                    '&[aria-selected=true]': {
                        color: baseTheme.palette.primary.contrastText,
                        backgroundColor: baseTheme.palette.primary.main,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    '& svg': {
                        marginRight: 10,
                    },
                    '&:focus': {
                        border: '2px solid #00ACFF',
                        boxShadow: '0 0 4px 1px rgba(0,172,255,0.5)',
                    },
                },
                contained: {
                    minWidth: 'fit-content',
                    textTransform: 'none',
                    paddingLeft: 24,
                    paddingRight: 24,
                    letterSpacing: 0,

                    '&:active, &[data-clicked=true]': {},
                },
                outlined: {
                    color: Colors.darkBlue,
                    borderColor: 'rgba(0, 32, 91, 0.5)',
                },
                containedSecondary: {
                    fontSize: '1.6rem',
                    paddingTop: 10,
                    paddingBottom: 10,
                    '&:hover': {
                        backgroundColor: '#FAD279',
                    },
                    '&:focus': {
                        boxShadow: '0 0 4px 1px rgba(0,172,255,0.5)',
                        border: '2px solid #00ACFF',
                        marginRight: -2,
                    },
                    [baseTheme.breakpoints.down('md')]: {
                        fontSize: '1.5rem',
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 5,
                    },
                },
                textPrimary: {
                    fontSize: '1.5rem',
                    padding: '10px 16px',
                    color: 'white',
                },
                textSecondary: {
                    fontSize: '1.7rem',
                    fontWeight: 600,
                    textDecoration: 'underline',
                    color: Colors.darkBlue,
                    paddingTop: 8,
                    paddingBottom: 8,
                    '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'initial',
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingRight: '35px !important',
                    paddingLeft: 15,
                },
            },
        },
    },
});

export default theme;
