export default {
    white: '#ffffff',
    whiteRGB: '255, 255, 255',
    whiteDark: '#F4F4F5',
    yellow: '#F7B31F',
    lightYellow: '#FAD279',
    grey: '#F0F1F3',
    blue: '#17609E',
    blueRGB: '23,96,158',
    navyBlue: '#2F4C67',
    navyBlueRGB: '47,76,103',
    darkBlue: '#011C40',
    purple: '#B297FC',
    green: '#63E5BE',
    peach: '#F9A466',
    pink: '#FE8787',
    error: '#C24444',
    success: '#13AE47',
};
