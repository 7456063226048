const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    SET_ANSWERS: true,
    CLEAR_ANSWERS: true,
    LOAD_CONTENT_BEGIN: true,
    LOAD_CONTENT_ERROR: true,
    LOAD_CONTENT_SUCCESS: true,
    GET_RESULTS_BEGIN: true,
    GET_RESULTS_ERROR: true,
    GET_RESULTS_SUCCESS: true,
});
