import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import ErrorImg from './images/500-error@2x.png';

const Error500 = () => {
    const navigate = useNavigate();
    return (
        <main>
            <Container style={{ textAlign: 'center' }}>
                <Box>
                    <Typography variant="h1" pt={4}>
                        Error
                    </Typography>

                    <Typography variant="h4" pb={4}>
                        Something went wrong, please try again.
                    </Typography>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { navigate('/'); window.location.reload(false); }}
                        style={{ fontSize: '1.4rem' }}
                    >
                        Back to Home
                    </Button>

                    <Box pt={4}>
                        <img src={ErrorImg} alt="" height="190" width="313" />
                    </Box>
                </Box>
            </Container>
        </main>
    );
};

export default Error500;
